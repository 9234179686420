

export const BASE_URI = "https://euchre-api.herokuapp.com/api/"
// export const BASE_URI = "http://localhost:3033/api/"

export function sleep(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}
// sleep(1500).then(() => setMatchStage("DEAL"));

export const srcArray = [
	"./bg-board.png",
	"./cards/c9.png",
	"./cards/c10.png",
	"./cards/ca.png",
	"./cards/cj.png",
	"./cards/ck.png",
	"./cards/cq.png",
	"./cards/d9.png",
	"./cards/d10.png",
	"./cards/da.png",
	"./cards/dj.png",
	"./cards/dk.png",
	"./cards/dq.png",
	"./cards/h9.png",
	"./cards/h10.png",
	"./cards/ha.png",
	"./cards/hj.png",
	"./cards/hk.png",
	"./cards/hq.png",
	"./cards/s9.png",
	"./cards/s10.png",
	"./cards/sa.png",
	"./cards/sj.png",
	"./cards/sk.png",
	"./cards/sq.png",
	"./cards/down5.png",
	"./cards/down4.png",
	"./cards/down3.png",
	"./cards/down2.png",
	"./cards/down1.png",
	"./cards/down0.png",
	"./cards/deck.png",
]
export const cacheImages = async (srcArray) => {
	const promises = await srcArray.map(src => {
		return new Promise((resolve, reject) => {
			const img = new Image()

			img.src = src
			img.onload = resolve()
			img.onerror = reject()
		})
	})

	await Promise.all(promises)
}

export const blankCard = {
	faceValue: "0",
	suit: {
		code: "0",
		name: "Blank",
		left: {
			code: "0",
			name: "0"
		}
	},
	value: 0
}

export const dealerIcon = <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
	<circle cx="24" cy="24" r="23.5" stroke="white" />
	<path opacity="0.65" d="M37.4158 13.1625L35.5175 12.3692V25.1617L38.96 16.86C39.5408 15.415 38.875 13.7575 37.4158 13.1625V13.1625ZM9.79083 18.4042L16.8175 35.3333C17.0244 35.8484 17.3773 36.2918 17.8329 36.6089C18.2885 36.926 18.8268 37.1031 19.3817 37.1183C19.75 37.1183 20.1325 37.0475 20.5008 36.8917L30.9417 32.5708C32.0042 32.1317 32.6558 31.0833 32.6842 30.035C32.6983 29.6667 32.6275 29.2558 32.5 28.8875L25.4167 11.9583C25.2164 11.4399 24.8648 10.9937 24.4074 10.6778C23.9501 10.362 23.4083 10.1911 22.8525 10.1875C22.4842 10.1875 22.1158 10.2725 21.7617 10.4L11.335 14.7208C10.6419 15.0047 10.0899 15.5521 9.80034 16.2428C9.51078 16.9335 9.50736 17.7109 9.79083 18.4042V18.4042ZM32.67 13.0208C32.67 12.2694 32.3715 11.5487 31.8401 11.0174C31.3088 10.486 30.5881 10.1875 29.8367 10.1875H27.7825L32.67 22.0025" fill="white" />
</svg>

export const spinner = <svg
	className="animate-spin"
	width="82"
	height="84"
	viewBox="0 0 82 84"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<path
		opacity="0.5"
		fillRule="evenodd"
		clipRule="evenodd"
		d="M40.9527 70.842C44.7172 70.842 48.445 70.0875 51.923 68.6215C55.401 67.1556 58.5612 65.0069 61.2232 62.2982C63.8851 59.5895 65.9967 56.3738 67.4374 52.8347C68.878 49.2956 69.6195 45.5024 69.6195 41.6718C69.6195 37.8411 68.878 34.0479 67.4374 30.5088C65.9967 26.9697 63.8851 23.754 61.2232 21.0453C58.5612 18.3366 55.401 16.1879 51.923 14.722C48.445 13.256 44.7172 12.5015 40.9527 12.5015C33.3497 12.5015 26.0582 15.5748 20.6821 21.0453C15.306 26.5158 12.2858 33.9353 12.2858 41.6718C12.2858 49.4082 15.306 56.8277 20.6821 62.2982C26.0582 67.7687 33.3497 70.842 40.9527 70.842V70.842ZM40.9527 83.3435C63.5708 83.3435 81.9053 64.6871 81.9053 41.6718C81.9053 18.6564 63.5708 0 40.9527 0C18.3345 0 0 18.6564 0 41.6718C0 64.6871 18.3345 83.3435 40.9527 83.3435Z"
		fill="black"
		fillOpacity="0.65"
	/>
	<path
		d="M40.9526 83.3435C63.5708 83.3435 81.9053 64.6871 81.9053 41.6718H69.6195C69.6195 49.4082 66.5992 56.8277 61.2232 62.2982C55.8471 67.7687 48.5556 70.842 40.9526 70.842V83.3435Z"
		fill="black"
		fillOpacity="0.65"
	/>
	<path
		d="M0 41.6718C0 18.6564 18.3345 0 40.9527 0V12.5015C33.3497 12.5015 26.0582 15.5748 20.6821 21.0453C15.306 26.5158 12.2858 33.9353 12.2858 41.6718H0Z"
		fill="black"
		fillOpacity="0.65"
	/>
</svg>

export const whiteSpinner = <svg
	className="animate-spin"
	width="82"
	height="84"
	viewBox="0 0 82 84"
	fill="none"
	xmlns="http://www.w3.org/2000/svg">
	<path
		opacity="0.5"
		fillRule="evenodd"
		clipRule="evenodd"
		d="M40.9527 70.842C44.7172 70.842 48.445 70.0875 51.923 68.6215C55.401 67.1556 58.5612 65.0069 61.2232 62.2982C63.8851 59.5895 65.9967 56.3738 67.4374 52.8347C68.878 49.2956 69.6195 45.5024 69.6195 41.6718C69.6195 37.8411 68.878 34.0479 67.4374 30.5088C65.9967 26.9697 63.8851 23.754 61.2232 21.0453C58.5612 18.3366 55.401 16.1879 51.923 14.722C48.445 13.256 44.7172 12.5015 40.9527 12.5015C33.3497 12.5015 26.0582 15.5748 20.6821 21.0453C15.306 26.5158 12.2858 33.9353 12.2858 41.6718C12.2858 49.4082 15.306 56.8277 20.6821 62.2982C26.0582 67.7687 33.3497 70.842 40.9527 70.842V70.842ZM40.9527 83.3435C63.5708 83.3435 81.9053 64.6871 81.9053 41.6718C81.9053 18.6564 63.5708 0 40.9527 0C18.3345 0 0 18.6564 0 41.6718C0 64.6871 18.3345 83.3435 40.9527 83.3435Z"
		fill="white"
		fillOpacity="0.65"
	/>
	<path
		d="M40.9526 83.3435C63.5708 83.3435 81.9053 64.6871 81.9053 41.6718H69.6195C69.6195 49.4082 66.5992 56.8277 61.2232 62.2982C55.8471 67.7687 48.5556 70.842 40.9526 70.842V83.3435Z"
		fill="white"
		fillOpacity="0.65"
	/>
	<path
		d="M0 41.6718C0 18.6564 18.3345 0 40.9527 0V12.5015C33.3497 12.5015 26.0582 15.5748 20.6821 21.0453C15.306 26.5158 12.2858 33.9353 12.2858 41.6718H0Z"
		fill="white"
		fillOpacity="0.65"
	/>
</svg>